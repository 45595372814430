import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: Login
  },
  {
    path: '/',
    name: 'login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/topic',
    name: 'topic',
    component: () => import('../views/Topic.vue')
  },
  {
    path: '/submit',
    name: 'submit',
    component: () => import('../views/Submit.vue')
  },
  {
    path: '/historical',
    name: 'historical',
    component: () => import('../views/Historical.vue')
  },
  {
    path: '/result',
    name: 'result',
    component: () => import('../views/Result.vue')
  },
  {
    path: '/moreresult',
    name: 'moreresult',
    component: () => import('../views/Moreresult.vue')
  },
  {
    path: '/viewPaper',
    name: 'viewPaper',
    component: () => import('../views/Viewpaper.vue')
  }, {
    path: '/entry',
    name: 'entry',
    component: () => import('../views/Entry.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router

<template>
  <div class="login">
    <div style="height: 39px;"></div>
    <div class="login_header">
      <img src="https://shuati.yoyolooming.com/a_test_system/login/test_system_logo.png" alt="" srcset="" />
    </div>
    <div class="login_wrapper">
      <!-- 左边海报部分 -->
      <div class="wrapper_left">
        <img src="https://shuati.yoyolooming.com/a_test_system/login/test_system_bg.png" alt="" />
      </div>
      <!-- 右边的登录部分 -->
      <div class="wrapper_right" v-loading="loginLoading">
        <div class="right_title">登录</div>
        <div class="tel-box">
          <div class="tel-icon">
            <img src="https://shuati.yoyolooming.com/a_test_system/login/test_system_tel.png" alt="" />
          </div>
          <div class="tel-input">
            <el-input v-model="phone" placeholder="请输入手机号" clearable @blur="phoneBlur" @input="phoneChange"></el-input>
          </div>
        </div>
        <div class="code-box" v-if="isToken">
          <div class="code-icon">
            <img src="https://shuati.yoyolooming.com/a_test_system/login/test_system_code.png" alt="" />
          </div>
          <div class="code-input">
            <el-input v-model="code" placeholder="请输入验证码"></el-input>
          </div>
          <button class="code-obtion" @click="obtainCode" :disabled="codeDisabled">
            {{ codeMessage }}
          </button>
        </div>
        <div class="login-button" @click="login">登录</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "login",
  data() {
    return {
      phone: "", // 用户手机号码
      code: "", // 用户验证码
      codeDisabled: false, // 获取验证码按钮是否可以点击
      codeMessage: "获取验证码", // 获取验证码按钮文案
      codeSeconds: 60, // 验证码重新获取倒计时
      pcode: "", // 试卷编号 url拼接的pcode参数
      tcode: "", // 试卷编号 url拼接的tcode参数
      isToken: true,
      token: '',
      loginLoading: false,
      paperId:''
    };
  },
  methods: {
    // 手机号输入框失焦 进行校验
    phoneBlur() {
      var tel =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      if (!tel.test(this.phone)) {
        this.$message.error("手机号码有误，请重新输入");
        this.phone = "";
      }
    },
    phoneChange() {
      if (localStorage.getItem('phone') && localStorage.getItem('phone') == this.phone) {
        this.isToken = false
      } else {
        this.isToken = true
      }
    },
    // 点击 获取验证码 按钮
    async obtainCode() {
      if (this.phone) {
        let params = {
          phone: this.phone,
        };
        await this.$axios
          .post("https://manage.yoyolooming.com/api/exam/account/sendVerificationCode", params)
          .then((res) => {
            if (res.data.data) {
              this.$message.success(res.data.data);
            } else {
              this.$message.success(res.data.msg);
            }
          });
        // 发送验证码 禁止点击获取验证码 显示重新获取倒计时
        this.codeDisabled = true;
        this.codeMessage = "重新发送" + this.codeSeconds + "s";
        // 开启定时任务进行获取验证码倒计时
        let timer = setInterval(() => {
          if (this.codeSeconds > 0) {
            this.codeSeconds--;
            this.codeMessage = "重新发送" + this.codeSeconds + "s";
          } else {
            clearInterval(timer);
            this.codeDisabled = false;
            this.codeSeconds = 60;
            this.codeMessage = "再次发送验证码";
          }
        }, 1000);
      } else {
        this.$message.error("请输入手机号码");
      }
    },
    // 点击 登录 按钮
    async login() {
      if (!this.phone) {
        return this.$message.error("请输入手机号");
      } else if (!this.code && this.isToken) {
        return this.$message.error("请输入验证码");
      } else {
        this.loginLoading = true
        if (this.isToken) {
          // 普通登录
          let params = {
            phone: this.phone,
            code: parseInt(this.code),
            pcode: this.pcode,
            tcode: this.tcode,
          };
          let token = this.token
          localStorage.setItem(
            "phone",
            this.phone
          );
          await this.$axios({
            // headers: {
            //   Authorization: token,
            // },
            method: 'post',
            // url: 'http://193.0.0.113:9527/api/exam/account/login',
            url: 'https://manage.yoyolooming.com/api/exam/account/login',
            data: params
          })
            .then((res) => {
              if (res.data.data) {
                this.loginLoading = false
                localStorage.setItem(
                  "token",
                  res.data.data.token
                );
                this.$router.push({ path: "/topic", query: { paperId: res.data.data.paperId } });
              } else {
                this.loginLoading = false
                this.$message.error(res.data.msg);
              }
            }).catch((error) => {
              this.loginLoading = false
              this.$message.error(error);
            });
        } else {
          // 无验证码登录
          let params = {
            pcode: this.pcode,
            tcode: this.tcode,
          };
          let token = this.token
          localStorage.setItem(
            "phone",
            this.phone
          );
          await this.$axios({
            headers: {
              Authorization: token,
            },
            method: 'post',
            // url: 'http://193.0.0.113:9527/api/exam/account/login',
            url: 'https://manage.yoyolooming.com//api/exam/account/tokenLogin',
            data: params
          })
            .then((res) => {
              if (res.data.data) {
                localStorage.setItem(
                  "token",
                  res.data.data.token
                );
                this.$router.push({ path: "/topic", query: { paperId: res.data.data.paperId } });
                this.$message.success(res.data.data.name + '同学，您好');
              } else {
                this.$message.error(res.data.msg);
              }
            }).catch((error) => {
              this.$message.error(error);
            });
        }
      }
    },
    // 截取url
    getUrlValue() {
      let url = window.location.href; // 获取页面的地址
      if (url.indexOf("?") != -1) {
        // 判断是否有参数
        let paramsArr = url.replace('#/', '').split("?")[1].split("&"); // 截取出参数
        let obj = {};
        paramsArr.forEach((element) => {
          obj[element.split("=")[0]] = element.split("=")[1];
        });
        this.pcode = obj.pcode;
        this.tcode = obj.tcode;
        this.paperId = obj.paperId;
      }
    },
  },
  mounted() {
    // 截取网址中的参数
    this.getUrlValue();
    if (localStorage.getItem('phone')) {
      this.phone = localStorage.getItem('phone')
    }
    if (localStorage.getItem('token')) {
      this.token = localStorage.getItem('token')
      this.isToken = false
    }
    if (localStorage.getItem('paperId')) {
      this.paperId = localStorage.getItem('paperId')
    }
  },
};
</script>
<style lang="less" scoped>
.login {
  width: 100%;
  height: 100vh;
  background: #f4f6f9;
  position: relative;

  .login_header {
    width: 327px;
    height: 54.54px;
    margin-left: 39px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .login_wrapper {
    // width: 1644px;
    // height: 800px;
    height: 80%; // 平板适配修改
    width: 90%; // 平板适配修改
    margin: auto;
    margin-top: 20px;
    display: flex;

    .wrapper_left {
      width: 50%;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .wrapper_right {
      width: 50%;
      height: 100%;
      background: #fff;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;

      .right_title {
        font-size: 38px;
        font-weight: 600;
        text-align: center;
        margin-top: 20%;
      }

      .tel-box {
        // width: 462px;
        width: 58%; // 平板适配修改
        height: 64px;
        border: 2px solid #dde3ee;
        background: #f7f9fc;
        border-radius: 12px;
        margin: auto;
        margin-top: 64px;
        display: flex;
        align-items: center;

        .tel-icon {
          width: 32px;
          height: 32px;
          margin-left: 12px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .tel-input {
          margin-left: 16px;
          font-size: 22px;


          /deep/.el-input__inner {
            background-color: #f7f9fc !important;
            border: none;
          }
        }
      }

      .code-box {
        // width: 462px;
        width: 58%; // 平板适配修改
        height: 64px;
        border: 2px solid #dde3ee;
        background: #f7f9fc;
        border-radius: 12px;
        margin: auto;
        margin-top: 40px;
        display: flex;
        align-items: center;
        position: relative;

        .code-icon {
          width: 32px;
          height: 32px;
          margin-left: 12px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .code-input {
          margin-left: 16px;
          font-size: 22px;
          font-size: 22px;

          /deep/.el-input__inner {
            background-color: #f7f9fc !important;
            border: none;
          }
        }

        .code-obtion {
          font-size: 16px;
          color: #2e5bfd;
          cursor: pointer;
          border: none;
          background: #f7f9fc;
          position: absolute;
          right: 16px;
          font-weight: bold;
        }
      }

      .login-button {
        cursor: pointer;
        // width: 343px;
        width: 42.5%; // 平板适配修改
        height: 44px;
        line-height: 44px;
        margin: auto;
        margin-top: 96px;
        background: #2e5bfd;
        color: #fff;
        border-radius: 2px;
        text-align: center;
        font-size: 18px;
        font-weight: bold;
      }
    }
  }
}
</style>